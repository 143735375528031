import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/imgs';
import './styles.scss';
import TButton from '../Button';
import { toggleModalOpen } from '../../../store/features/openModalLogin';
import { useAppDispatch } from '../../../store';
import { useAuthContext } from '../../../context/AuthContext';
import LoginProfileIcon from '../../icons/LoginProfileIcon';
import LogoutIcon from '../../icons/LogoutIcon';

function ButtonWallet({ isShowText = true }) {
  const [isConnect, setConnect] = useState(false);
  const [isCopy, setCopy] = useState(false);
  const walletRef = useRef(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isLogin, handleLogOut, userEmail, walletAccount } = useAuthContext();
  const { t } = useTranslation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (walletRef.current && !walletRef.current.contains(event.target)) {
        if (isLogin) {
          setConnect(false);
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [walletRef, isConnect]);

  const goToEditProfile = () => {
    history.push('/profile');
  };

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 500);
  };

  const handleShow = () => {
    if (isLogin) {
      setConnect(!isConnect);
    } else {
      dispatch(toggleModalOpen());
    }
  };

  const accountShow = userEmail || walletAccount;
  const renderTitle = () => {
    if (isShowText) {
      return accountShow ? (
        <div className="login-profile-icon">
          <LoginProfileIcon />
          {t('profile.title')}
        </div>
      ) : (
        t('login.loginButton')
      );
    }
    return <LoginProfileIcon />;
  };

  return (
    <>
      <div
        className={`btn-custom-wallet ${!isShowText && 'w-profile-custom'}`}
        ref={walletRef}
        onClick={handleShow}
        aria-hidden
      >
        <TButton
          title={renderTitle()}
          className={`btn-custom-wallet ${!isShowText && 'w-profile-custom'}`}
        />
        <div
          className={isConnect ? 'd-block wrap-sub-wallet' : 'wrap-sub-wallet'}
          aria-hidden="true"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="title">{t('profile.yourAddress')} </div>
          <CopyToClipboard text={accountShow} onCopy={handleCopy}>
            <div className="d-flex align-items-center mb-3" id="id-copy-tooltip">
              <h6 id="copyId">{accountShow}</h6>
              <img src={ICONS.iconCopy} alt="icon" />
            </div>
          </CopyToClipboard>

          <Row className="justify-content-center">
            <div className="custom-btn-wrapper">
              <TButton
                title={t('profile.edit')}
                leftIcon={ICONS.iconEditProfile}
                className="btn-custom-edit-profile"
                onClick={goToEditProfile}
              />
              <TButton
                title={<LogoutIcon />}
                className="btn-back-ground-logout"
                onClick={() => {
                  handleLogOut();
                  setConnect(false);
                }}
              />
            </div>
          </Row>
          <div className="line" />
        </div>
      </div>
      <Tooltip target="id-copy-tooltip" placement="top" isOpen={isCopy}>
        {t('copy.copied')}
      </Tooltip>
    </>
  );
}

export default ButtonWallet;
