import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HOME, ICONS } from '../../../../assets/imgs';
import useScreenWidth from '../../../hooks/useScreenWidth';
import ButtonWallet from '../ButtonWallet';
import Footer from '../Footer';
import NotificationIcon from '../Notification';
import SideBar from '../SideBar';
import { useAppDispatch } from '../../../store';
import './styles.scss';
import NewModalLogin from '../Modal/NewModalLogin';
import { isOpenModalLoginSelector } from '../../../store/features/openModalLogin';
import { useAuthContext } from '../../../context/AuthContext';
import { useMaintenanceContext } from '../../../context/MaintenanceContext';
import { ConnectorNames, connectorsByName } from '../../../lib/connectors';
import WrongWalletWarningPopup from '../Modal/WrongWalletWarningPopup';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import shortenAddress from '../../../utils/shortenAddress';
import SelectLanguageDropdown from './SelectLanguageDropdown';
import WarningIcon from '../../icons/WarningIcon';

function Layout(props) {
  const { title, type, dataConnect, link, query = '' } = props;
  const [isShow, setShow] = useState(window.innerWidth > 768);
  const node = useRef();
  const screenWidth = useScreenWidth();
  const history = useHistory();
  const pathRental = '/assets';
  const typeDetail = 1;
  const [isOpenWalletWarningPopup, setIsOpenWalletWarningPopup] = useState(false);
  const { account, connector } = useActiveWeb3React();
  const { t } = useTranslation();

  const {
    isLogin,
    isCreatedProfile,
    isLinkedEmaiAddress,
    walletAccount,
    checkLogin,
    checkCreatedProfile,
    handleLogOut,
    checkLinkedEmailAddress,
  } = useAuthContext();
  const { checkMaintenance, isMaintenance } = useMaintenanceContext();

  useEffect(() => {
    checkLogin();
    checkCreatedProfile();
    checkLinkedEmailAddress();
    checkMaintenance();
  }, []);
  useEffect(() => {
    if (isMaintenance) {
      history.push('/service-unavailable');
    }
  }, [isMaintenance]);

  const handleShow = () => {
    setShow(!isShow);
  };
  const goToHome = () => {
    if (isLogin && (!isCreatedProfile || !isLinkedEmaiAddress)) return;
    history.push('/');
  };
  const goBack = () => {
    if (type === typeDetail) {
      if (link === pathRental) {
        history.goBack();
      } else {
        history.push({
          pathname: `${link}${query}`,
        });
      }
    }
  };

  const handleClickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    if (e.target.className === 'menu-icon cursor-pointer' || e.target.className === 'logo-icon') {
      return;
    }
    setShow(false);
  };

  useEffect(() => {
    if (screenWidth > 768) {
      return undefined;
    }
    if (isShow) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isShow, screenWidth]);

  const isOpenModalLogin = useSelector(isOpenModalLoginSelector);
  const dispatch = useAppDispatch();

  const handleToggleModalLogin = () => {
    dispatch(toggleModalOpen());
  };

  useEffect(() => {
    if (isLogin) {
      const connectorSelected = localStorage.getItem('connector');
      const walletSession = localStorage.getItem('wc@2:client:0.3//session');
      const isOpenedWalletWarning = localStorage.getItem('isOpenedWalletWarning');

      if (!connectorSelected) {
        handleLogOut();
      }
      if (
        connectorSelected === ConnectorNames.DgPubWalletDesktop &&
        (walletSession === '[]' || !walletSession)
      ) {
        handleLogOut();
      }
      if (localStorage.getItem('connector') === ConnectorNames.Email) return;
      if (connectorSelected && isOpenedWalletWarning !== '2') {
        connectorsByName[connectorSelected].connectEagerly().catch(() => {
          console.debug(`Failed to connect eagerly to ${connectorSelected}`);
        });
      }
    }
  }, [isLogin]);

  useEffect(() => {
    const isOpenedWalletWarning = localStorage?.getItem('isOpenedWalletWarning');

    if (
      isLogin &&
      account &&
      walletAccount !== '0' &&
      walletAccount !== account &&
      !isOpenedWalletWarning
    ) {
      setIsOpenWalletWarningPopup(true);
    } else {
      setIsOpenWalletWarningPopup(false);
    }
  }, [isLogin, account]);

  return (
    <div className="container-fluid new-layout">
      <div className="bg-header">
        <div className="btn-menu-and-logo">
          <div className="btn-menu" onClick={handleShow} aria-hidden>
            <img src={ICONS.iconMenu} alt="dgpub-network" className="menu-icon cursor-pointer" />
          </div>
          <div
            className={`btn-logo ${
              isLogin && (!isCreatedProfile || !isLinkedEmaiAddress) && 'auto-pointer'
            } cursor-pointer`}
            onClick={goToHome}
            aria-hidden
          >
            <img src={HOME.logoSideBar} alt="dgpub-network" className="logo-icon" />
            <img src={HOME.logoSideBarMobile} alt="dgpub-network" className="logo-icon-mobile" />
          </div>
        </div>
        <div className="btn-login-and-notification">
          {screenWidth > 768 && <SelectLanguageDropdown />}
          {isLogin && <NotificationIcon img={ICONS.iconNotify} dataConnect={dataConnect} />}
          <ButtonWallet />
          {!isLogin && <NewModalLogin show={isOpenModalLogin} setShow={handleToggleModalLogin} />}
        </div>
      </div>
      {isOpenWalletWarningPopup && (
        <WrongWalletWarningPopup
          show={isOpenWalletWarningPopup}
          setShow={setIsOpenWalletWarningPopup}
          message={t('success.submit')}
          linkedWallet={shortenAddress(walletAccount || '')}
          injectedAccount={shortenAddress(account || '')}
          connector={connector}
        />
      )}
      <div className="side-bar-and-content">
        <div
          className={`layout-side-bar ${isShow || 'w-full'} ${
            screenWidth < 768 && 'side-bar-mobile'
          }`}
        >
          <SideBar isShow={isShow} sideBarRef={node} setShow={setShow} />
        </div>
        <div
          className={`content ${isShow || 'content-full'} ${screenWidth < 768 && 'mobile-full'}`}
        >
          {isLogin && account && walletAccount !== '0' && walletAccount !== account && (
            <div className="wrong-wallet-wrapper">
              <WarningIcon width={40} height={40} color="#FBB03B" />
              <div>
                <span className="warning-text">Warning: </span>
                {t('alert.warning.description1')}
                <span>{shortenAddress(walletAccount)}</span>
                {t('alert.warning.description2')}
                <span>DG Pub </span>
                {t('alert.warning.description3')}
                <span>{shortenAddress(account)}.</span>
                {t('alert.warning.description4')}
                <span>{shortenAddress(account)}.</span>
              </div>
            </div>
          )}
          <div className="body-content">
            {title && (
              <div
                className={`${type === typeDetail ? 'name-detail' : ''} title-layout`}
                onClick={goBack}
                aria-hidden
              >
                {type === typeDetail && <img src={ICONS.iconBack} alt="icon" />}
                <h1 className="title-layout-top">{title}</h1>
              </div>
            )}
            <div className="style-content">{props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
