import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useQueries } from '@tanstack/react-query';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import './style.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/common/Layout';
import { ICONS, REFERRAL } from '../../../assets/imgs';
import ReferralStep1 from '../../components/icons/ReferralStep1';
import ReferralStep2 from '../../components/icons/ReferralStep2';
import ReferralStep3 from '../../components/icons/ReferralStep3';
import Pagination from '../../components/common/Pagination';
import referralApi from '../../services/referral';
import scholarshipApi from '../../services/scholarship';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import { useAuthContext } from '../../context/AuthContext';
import TButton from '../../components/common/Button';
import SelectCustom from '../../components/common/SelectCustom';
import ReferralTable from '../../components/common/CommonTable/ReferralTable';
import formatPointNumber from '../../lib/formatPointNumber';
import ShowTxHash from '../../components/common/ShowTxHash/ShowTxHash';
import ReferralOption from './ReferralOption';
import { CUSTOM_TIMEOUT_MILISEC, GAME_TYPE } from '../../constants';
import ReferralOverview from './ReferralOverview/ReferralOverview';
import shortenAddress, { shortenString } from '../../utils/shortenAddress';
import PrimaryInput from '../../components/common/Input/PrimaryInput';
import SearchIcon from '../../components/icons/SearchIcon';
import ModalDateRange from '../../components/common/Modal/ModalDateRange';
import ReferralRewardTab from './ReferralRewardTab';
import useDebounce from '../../hooks/useDebounce';

const RenderTournamentName = (data) => (
  <div className="tournament-title cursor-pointer" aria-hidden="true">
    <a href={`/tournament/${data?.tournamentSlug}`} rel="noreferrer">
      {data?.tournamentName}
    </a>
  </div>
);
const RenderAmountNumber = (data) => {
  const amount = data?.amount;
  if (amount) {
    return (
      <p>
        {formatPointNumber(amount)} {data?.tokenSymbol}
      </p>
    );
  }
  return <p>-</p>;
};

const RenderDateTime = (data) => (
  <p>
    {format(parseISO(data?.createdAt ? data?.createdAt : new Date().toISOString()), 'dd/MM/yyyy')}
  </p>
);

const RenderFromPeople = (data) => {
  if (data?.lastName) {
    return (
      <div className="point-item">{shortenString(`${data?.firstName} ${data?.lastName}`, 40)}</div>
    );
  }
  return (
    <div className="point-item">
      {String(data?.from).includes('@') ? (
        shortenAddress(data?.from, 4)
      ) : (
        <ShowTxHash txLength={6} txHash={data?.from} />
      )}
    </div>
  );
};

function Referral(props) {
  const { dataConnect } = props;
  const { t } = useTranslation();
  const headerTextAlignCenter = [t('referral.amountHeader'), t('referral.tierHeader')];

  const columnsMyReward = [
    {
      Header: ' ',
      columns: [
        {
          Header: t('referral.tournamentHeader'),
          accessor: (data) => RenderTournamentName(data),
        },
        {
          Header: t('referral.gameHeader'),
          accessor: (data) => <p className="">{data?.gameName}</p>,
        },
        {
          Header: t('referral.amountHeader'),
          accessor: (data) => RenderAmountNumber(data),
        },
        {
          Header: t('referral.dateHeader'),
          accessor: (data) => RenderDateTime(data),
        },
        {
          Header: t('referral.tierHeader'),
          accessor: (data) => <p>Tier {data?.referralLevel}</p>,
        },
        {
          Header: t('referral.fromHeader'),
          accessor: (data) => RenderFromPeople(data),
        },
      ],
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState('');
  const debouncedSearchValue = useDebounce(searchParams, CUSTOM_TIMEOUT_MILISEC.DEBOUNCED_TIME);

  const itemsPerPage = 10;
  const [totalPage, setTotalPage] = useState([]);
  const [referralReward, setReferralReward] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const history = useHistory();
  const [gameFilter, setGameFilter] = useState();
  const [referralOverview, setReferralOverview] = useState();
  const [listGame, setListGame] = useState([]);
  const [listChain, setListChain] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [dateRange, setDateRange] = useState(t('filter.dateRange'));
  const [params, setParams] = useState();

  const { isLogin } = useAuthContext();
  const [activeTab, setActiveTab] = useState({
    id: 'referral-reward',
    label: t('Referral Rewards'),
  });
  const listSubTab = [
    { id: 'referral-reward', label: t('Referral Rewards') },
    { id: 'history', label: t('History') },
  ];

  useQueries({
    queries: [
      {
        queryKey: ['getListGame'],
        queryFn: async () => {
          const res = await scholarshipApi.getListGame();
          setListGame(res?.data);
          return res?.data;
        },
      },
      {
        queryKey: ['getProfile'],
        queryFn: async () => {
          const res = await scholarshipApi.getProfile();
          setUserProfile(res?.data);
          return res?.data;
        },
      },
      {
        queryKey: ['getReferralOverview'],
        queryFn: async () => {
          const res = await referralApi.getReferralOverview();
          setReferralOverview(res?.data);
          return res?.data;
        },
      },
      {
        queryKey: ['getReferralReward', debouncedSearchValue, currentPage, itemsPerPage, params],
        queryFn: async () => {
          const queryObj = {
            page: currentPage,
            limit: itemsPerPage,
            ...params,
          };
          if (debouncedSearchValue) {
            queryObj.search = debouncedSearchValue;
          }
          const res = await referralApi.getReferralReward(queryObj);
          setReferralReward(res?.data);
          setTotalPage(res?.data?.totalPage);
          return res?.data;
        },
      },
      {
        queryKey: ['getListChain'],
        queryFn: async () => {
          const res = await scholarshipApi.getListNetworks({
            isTournamentNetwork: true,
          });
          setListChain(res?.data);
          return res?.data;
        },
      },
    ],
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (gameFilter) {
      setParams({
        ...params,
        gameId: gameFilter,
      });
      setCurrentPage(1);
    }
  }, [gameFilter]);

  const [hostName, setHostName] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHostName(window.location.origin);
    }
  }, []);

  const saveDateRange = () => {
    const parameter = { ...params };
    parameter.from = new Date(selectionRange.startDate);
    const currentEndDate = new Date(selectionRange.endDate);
    parameter.to = new Date(currentEndDate.setDate(currentEndDate.getDate() + 1));
    setDateRange(
      `${moment(selectionRange.startDate).format('DD/MM/YYYY')}-${moment(
        selectionRange.endDate,
      ).format('DD/MM/YYYY')}`,
    );
    setParams(parameter);
    setCurrentPage(1);
    setModalIsOpen(false);
  };
  const selectDateRange = () => {
    setModalIsOpen(true);
  };
  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleResetFilter = () => {
    setDateRange(t('filter.dateRange'));
    setDateRange(t('filter.dateRange'));
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    setParams(undefined);
    setSearchParams('');
    setGameFilter(undefined);
    setCurrentPage(1);
  };

  return (
    <Layout title={t('referral.referralProgram')} dataConnect={dataConnect}>
      {isLogin ? (
        <>
          <div className="banner-img-wrapper">
            <div
              className="commission-wrapper"
              style={{
                width: '100%',
                backgroundImage: `url(${REFERRAL.referralBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <img
                src={REFERRAL.cardCommission}
                alt="card-commission"
                className="commission-card"
              />
              <div className="hidden-card" />
              <div>
                <h2 className="title-banner">{t('referral.inviteFriend')} </h2>
                <p className="commission">{t('referral.get')}</p>
              </div>
            </div>
            <div className="referral-option-bar">
              <ReferralOption
                label={t('referral.referralCode')}
                content={userProfile?.referralCode}
              />
              <ReferralOption
                label={t('referral.referralLink')}
                content={`${hostName}?referralCode=${userProfile?.referralCode}`}
              />
              <div className="copy-link-wrapper">
                <p className="title-copy-link">{t('referral.orShareVia')}</p>
                <div className="list-social-wrapper">
                  <FacebookShareButton
                    url={`${hostName}?referralCode=${userProfile?.referralCode}`}
                    quote={t('referral.joinWithMe')}
                  >
                    <FacebookIcon size={32} round="true" />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`${hostName}?referralCode=${userProfile?.referralCode}`}
                    title={t('referral.joinWithMe')}
                  >
                    <TwitterIcon size={32} round="true" />
                  </TwitterShareButton>
                  <TelegramShareButton
                    url={`${hostName}?referralCode=${userProfile?.referralCode}`}
                    title={t('referral.joinWithMe')}
                  >
                    <TelegramIcon size={32} round="true" />
                  </TelegramShareButton>
                </div>
              </div>
            </div>
          </div>
          <div className="referral-body">
            <div className="how-it-work">
              <h2 className="referral-title">{t('referral.howItWorks?')}</h2>
            </div>
            <div className="how-it-work-step">
              <HowItWorkItem icon={<ReferralStep1 />} content={t('referral.content1')} order={1} />
              <HowItWorkItem icon={<ReferralStep2 />} content={t('referral.content2')} order={2} />
              <HowItWorkItem icon={<ReferralStep3 />} content={t('referral.content3')} order={3} />
            </div>
            <div className="overview-title">
              <p className="referral-title">{t('referral.referralOverview')}</p>
            </div>
            <ReferralOverview referralOverview={referralOverview} />
            <div className="list-tab">
              {listSubTab.map((tab) => (
                <TabLabel
                  key={tab.id}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  label={tab.label}
                  tab={tab}
                />
              ))}
            </div>
            {activeTab.id === 'referral-reward' && (
              <ReferralRewardTab listGame={listGame} listChain={listChain} />
            )}
            {activeTab.id === 'history' && (
              <div>
                <div className="my-reward-wrapper">
                  <PrimaryInput
                    className="search-name-wrapper"
                    accessoriesLeft={<SearchIcon />}
                    placeholder={t('search.searchByName')}
                    value={searchParams || ''}
                    onChange={(e) => {
                      setSearchParams(e.target.value);
                      setCurrentPage(1);
                    }}
                  />
                  <div className="select-btn-wrapper">
                    <div className="btn-select-game-wrapper">
                      <SelectCustom
                        items={listGame}
                        selectedItem={gameFilter}
                        setSelectedItem={setGameFilter}
                        classNameDropdown="btn-select-game"
                        type={GAME_TYPE}
                        textDefault={t('referral.selectGame')}
                        selectAllGame
                      />
                    </div>
                    <div className="btn-select-date-range" onClick={selectDateRange} aria-hidden>
                      {dateRange}
                      <img src={ICONS.iconCalendar} alt="dgpub-network" className="icon-calendar" />
                    </div>
                    <div className="btn-reset-filter-wrapper">
                      <TButton
                        disabled={!params}
                        onClick={handleResetFilter}
                        className="btn-clear-filter"
                      >
                        {t('referral.clearFilters')}
                      </TButton>
                    </div>
                  </div>
                </div>
                <div className="referral-table">
                  <ReferralTable
                    headerTextAlignCenter={headerTextAlignCenter}
                    pageSizePagination={itemsPerPage}
                    columns={columnsMyReward}
                    data={referralReward?.referralsOfUser}
                  />
                  <div className="referral-pagination">
                    <Pagination
                      totalPage={totalPage}
                      onChange={handlePageChange}
                      forcePage={currentPage}
                    />
                    <div className="btn-transaction-history-wrapper">
                      <TButton
                        title={t('referral.viewAllTransactionHistory')}
                        className="btn-transaction-history"
                        onClick={() => {
                          history.push('/transaction-history');
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ModalDateRange
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            selectionRange={selectionRange}
            handleSelect={handleSelect}
            saveDateRange={saveDateRange}
          />
        </>
      ) : (
        <UnauthorizePage />
      )}
    </Layout>
  );
}

export default Referral;

function HowItWorkItem({ order, icon, content = '' }) {
  const { t } = useTranslation();
  return (
    <div className="how-it-work-item">
      <div className="how-it-work-icon">{icon}</div>
      <div>
        <h4>
          {t('referral.step')} {order}
        </h4>
        <p>{content}</p>
      </div>
    </div>
  );
}

function TabLabel({ tab, activeTab, setActiveTab }) {
  return (
    <div
      className={`tab-label ${
        activeTab?.id === tab?.id ? 'tab-label-active' : 'tab-label-deactive'
      }`}
      onClick={() => setActiveTab(tab)}
      aria-hidden
    >
      {tab?.label}
    </div>
  );
}
