import React from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../Button';
import './styles.scss';

function WrongWalletWarningPopup(props) {
  const { show, setShow, linkedWallet = '', injectedAccount = '', connector } = props;
  const { t } = useTranslation();

  const handleDeactiveWallet = () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    localStorage.setItem('isOpenedWalletWarning', '2');
    setShow(false);
  };

  const handleContinueLogin = () => {
    localStorage.setItem('isOpenedWalletWarning', '1');
    setShow(false);
  };

  return (
    <div className="modal-wrapper-gray">
      <div className={show ? 'wrap-modal-success wrap-modal-shadow d-block' : 'wrap-modal-success'}>
        <div className="group-header">
          <h6 className="color-primary mb-2" style={{ fontSize: '24px' }}>
            {t('alert.warning.title')}
          </h6>
        </div>

        <div className="warning-message">
          {t('alert.warning.description1')}
          <span className="text-primary">{linkedWallet}</span>
          {t('alert.warning.description2')}
          <span className="text-subBlue">DG Pub. </span>
          {t('alert.warning.description3')}
          <span className="text-primary">{injectedAccount}.</span>
          {t('alert.warning.description4')}
          <span className="text-primary">{injectedAccount}.</span>
          {t('alert.warning.description5')}
        </div>

        <div className="btn-wrapper-warning">
          <TButton onClick={handleDeactiveWallet} className="secondary-btn">
            {t('Connect Another Wallet')}
          </TButton>
          <TButton onClick={handleContinueLogin} className="login-btn">
            {t('Yes, Proceed')}
          </TButton>
        </div>
      </div>
    </div>
  );
}

export default WrongWalletWarningPopup;
