import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import DonutChart from '../DonutChart';

function ReferralOverview({ referralOverview }) {
  const { t } = useTranslation();
  return (
    <div className="referral-overview">
      <DonutChart
        amountData={referralOverview?.summaries}
        totalNumber={referralOverview?.totalReferrals}
        title={t('referral.referralTiers')}
        totalTitle={t('Total Referrals')}
      />
    </div>
  );
}

export default ReferralOverview;
