import olAxios from '../api/Interceptors';
import { convertObjectToQueryString } from '../lib';

const tournamentApi = {
  getInviteMembers: (params) => olAxios.get('/tournament/team/invite-user', { params }),
  getMyTeam: (params) => olAxios.get('/tournament/team/my-team', { params }),
  getMyTeamDetail: (id) => olAxios.get(`/tournament/team/detail/${id}`),
  createMyTeam: (data) => olAxios.post('/tournament/team/create', data),
  updateMyTeam: (data) => olAxios.post('/tournament/team/update', data),
  deleteMyTeam: (id) => olAxios.delete(`/tournament/team/${id}`),
  inviteMember: (data) => olAxios.post('/tournament/team/invite', data),
  getInviteTeam: () => olAxios.get('/tournament/team/invite'),
  applyTeam: (data) => olAxios.post('/tournament/team/invite/apply', data),
  inviteEmail: (data) => olAxios.post('/tournament/team/invite/email', data),
  rejectPendingMember: (data) => olAxios.post('/tournament/team/invite/reject', data),
  rejectCurrentMember: (params) => olAxios.delete('/tournament/delete-user', { params }),
  pendingMember: (params) => olAxios.get('/tournament/team/invite/pending', { params }),
  getCurrentMember: (params, id) => olAxios.get(`/tournament/user-team/${id}`, { params }),
  getTournaments: (params) => olAxios.get(`/tournament${convertObjectToQueryString(params)}`),
  getMyTournaments: (params) => olAxios.get('/tournament/list/my-tournament', { params }),
  getTournamentsByStatus: (params) => olAxios.get('/tournament', { params }),
  getTournamentBySlug: (slug) => olAxios.get(`/tournament/get-by-slug/${slug}`),
  getParticipants: (params, id) => olAxios.get(`/tournament/participants/${id}`, { params }),
  getListBanner: () => olAxios.get('/tournament-banner'),
  getLeaderBoard: (params) => olAxios.get('/tournament/list/leaderboard', { params }),
  getListToken: () => olAxios.get('/tournament/list/token'),
  getListBracket: () => olAxios.get('/tournament/list/bracket'),
  joinTournament: (data) => olAxios.post('/tournament/create/request', data),
  getBrackets: (id) => olAxios.get(`/tournament/bracket/match?tournament_id=${id}`),
  sendCoinTournament: (data) => olAxios.post('/tournament/update/payment', data),
  getPrizes: (id) => olAxios.get(`/tournament/prizes/${id}`),
  sendDonate: (params) => olAxios.post('/tournament/donate', { params }),
  getBattleRoyaleReward: (params) => olAxios.get(`/reward/battle-royales`, { params }),
  getOverViewReward: (params) => olAxios.get(`/reward`, { params }),
  submitUseTicket: (data) => olAxios.post('/partner/tournament-ticket/submit-use-ticket', data),
  getListGamePoint: () => olAxios.get(`/game-points`),
  getListPointSymbol: (params) => olAxios.get(`/list/game-points`, { params }),
  getAccountPoint: (params) => olAxios.get(`/loyalty-points/balances`, { params }),
  sendRedeemRequest: (data) => olAxios.post('/tournament/reward-claim-requests', data),
  getRewardClaimDetail: (id) => olAxios.get(`/tournament/reward-claim-requests/${id}`),
  sendClaimTicketLog: (data) => olAxios.post(`/tournament/claim-rewards`, data),
  getRedeemRequestHistory: (params) => olAxios.get('/tournament/reward-claim-requests', { params }),
  getTransactionHistory: (params) => olAxios.get(`/loyalty-points/changes`, { params }),
  sendUserResponse: (params) => olAxios.post('/mail/contact', params),
  getTournamentStatistic: (id) => olAxios.get(`/tournament/${id}/statistics`),
  getTournamentTicketMaster: (id) => olAxios.get(`/tournament/${id}/ticket-masters`),
  getTournamentTopWinner: (id) => olAxios.get(`/tournament/${id}/top-winners`),
  getMaintenanceStatus: () => olAxios.get(`/maintenances`),
  getNumberOfTicket: (ticketAddress) =>
    olAxios.get(`partner/battle-royale-tournaments/${ticketAddress}/remaining-turns`),
};
export default tournamentApi;
