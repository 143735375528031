import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import useScreenWidth from '../../hooks/useScreenWidth';

Chart.register(CategoryScale);

function DonutChart({ amountData = [], totalNumber = 0, title = '', totalTitle = '' }) {
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();
  const screenWidth = useScreenWidth();

  const [textCenter, setTextCenter] = useState({
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      const positionX = chart.getDatasetMeta(0).data[0].x;
      const positionY = chart.getDatasetMeta(0).data[0].y;
      const lineHeight = 16;
      ctx.save();

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      ctx.font = 'bold 14px Montserrat';
      ctx.fillStyle = '#FFDA00';
      ctx.fillText(`0`, positionX, positionY - lineHeight);
      ctx.font = 'medium 14px Montserrat';
      ctx.fillStyle = '#f5f5f5';
      ctx.fillText(totalTitle, positionX, positionY + lineHeight);
    },
  });

  const options = {
    cutout: '80%',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = ' ';
            if (context.raw !== null) {
              label += context.raw + '%';
            }
            return label;
          },
        },
      },
    },
  };

  useEffect(() => {
    if (amountData?.length > 0) {
      const chartDataNumber = amountData?.map((i) =>
        i?.total ? Math.round((Number(i?.total || 1) / Number(totalNumber || 1)) * 100) : 0,
      );

      const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart) {
          const { ctx } = chart;
          const positionX = chart.getDatasetMeta(0).data[0].x;
          const positionY = chart.getDatasetMeta(0).data[0].y;
          const lineHeight = 16;
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          ctx.font = 'bold 26px Montserrat';
          ctx.fillStyle = '#FFDA00';
          ctx.fillText(`${totalNumber}`, positionX, positionY - lineHeight);
          ctx.font = 'bold 20px Montserrat';
          ctx.fillStyle = '#f5f5f5';
          ctx.fillText(totalTitle, positionX, positionY + lineHeight);
        },
      };

      setTextCenter(textCenter);
      setChartData({
        labels: [t('filter.tier1'), t('filter.tier2')],
        datasets: [
          {
            data: chartDataNumber,
            backgroundColor: ['#00FFFF', '#e5c000'],
            borderColor: 'transparent',
          },
        ],
        hoverOffset: 30,
      });
    }
  }, [amountData]);

  const doughnutLabelsLine = {
    id: 'doughnutLabelsLine',
    afterDraw(chart) {
      const {
        ctx,
        chartArea: { width, height },
      } = chart;
      chart.data.datasets?.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint?.tooltipPosition();
          if (Number(dataset.data[index]) === 0 || Number(dataset.data[index]) === 100) {
            return;
          }
          ctx.fillStyle = dataset.backgroundColor[index];

          // Draw line
          const halfWidth = width / 2;
          const halfHeight = height / 2;
          const xLine = x >= halfWidth ? x + 25 : x - 25;
          const yLine = y >= halfHeight ? y + 25 : y - 25;
          const extraLine = x >= halfWidth ? 220 : -220;

          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.setLineDash([5, 3]);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = dataset.backgroundColor[index];
          ctx.stroke();

          // Write text
          ctx.font = '18px Montserrat';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = dataset.backgroundColor[index];
          const positionPercentText = x >= halfWidth ? yLine - 16 : yLine + 16;
          ctx.fillText(`${dataset.data[index]} %`, xLine + extraLine / 2, positionPercentText);
        });
      });
    },
  };

  return (
    <div className="donut-overview-wrapper">
      <div className={`donut-chart-wrapper ${totalNumber === 0 && 'donut-zero'}`}>
        {chartData && (
          <Doughnut
            data={chartData}
            options={options}
            plugins={
              screenWidth > 768 && totalNumber !== 0
                ? [textCenter, doughnutLabelsLine]
                : [textCenter]
            }
            className="custom-donut-chart"
          />
        )}
      </div>
      <p className="referral-small-title">{title}</p>
      <div className="tier-line-wrapper">
        {amountData?.map((i) => (
          <TierLine key={i?.id} data={i} />
        ))}
      </div>
    </div>
  );
}

export default DonutChart;

function TierLine({ className = '', data }) {
  const { t } = useTranslation();
  return (
    <div className="tier-wrapper">
      <div
        className={`dot ${data?.referralLevel === 1 ? 'blue-dot' : 'yellow-dot'} ${className}`}
      />
      <p className="tier-value">
        {t('filter.tier')} {data?.referralLevel}: {data?.total || data?.amount || '-'}{' '}
        {data?.tokenSymbol}
      </p>
    </div>
  );
}
