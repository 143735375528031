import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../icons/SearchIcon';
import { search } from '../../../lib/search';
import { GAME_TYPE, SEARCH_TYPE } from '../../../constants';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

function SelectCustom(props) {
  const { t } = useTranslation();
  const {
    title,
    items,
    selectedItem,
    setSelectedItem,
    onChange,
    type = 'default',
    classNameDropdown = '',
    textDefault = t('select'),
    selectAllGame = false,
    classNameTitle = '',
    keyShow = 'id',
  } = props;
  const [isOpen, setOpen] = useState(false);
  const loginRef = useRef(null);
  const handleItemClick = (id) => {
    if (selectedItem === id) {
      onChange(id);
      setOpen(false);
    } else {
      setSelectedItem(id);
      setOpen(false);
    }
  };
  const toggleDropdown = () => setOpen(!isOpen);
  useEffect(() => {
    function handleClickOutside(event) {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [loginRef]);

  const [searchInput, setSearchInput] = useState('');
  const listResult = search(searchInput, items);

  const selected = listResult.find((item) => item?.[keyShow] === selectedItem);
  return (
    <div className="select-custom" ref={loginRef}>
      {title && <h6 className={`title ${classNameTitle}`}>{title}</h6>}
      <div className={`select ${classNameDropdown}`} onClick={toggleDropdown} aria-hidden>
        <h6 className="select-default">
          {selected?.name || selected?.pointSymbol || selected?.network_name || textDefault}
        </h6>
        <div className={`${isOpen && 'rotate-180'} smooth-transform`}>
          <ArrowDownIcon />
        </div>
      </div>
      <div className="list-item">
        <div className={`dropdown-body ${isOpen && 'open'}`}>
          {type === SEARCH_TYPE && (
            <DropdownSearch
              onChange={(e) => setSearchInput(e.target.value)}
              className=""
              placeholder={t('search.searchByName')}
              type={type}
            />
          )}
          {selectAllGame && (
            <div className="item-wrapper" onClick={() => handleItemClick(0)} aria-hidden>
              <div className="item-title">{t('allGame')}</div>
            </div>
          )}
          {listResult?.map((item) => (
            <div
              className="item-wrapper"
              onClick={() => handleItemClick(item?.[keyShow])}
              key={item.id}
              aria-hidden
            >
              {type === SEARCH_TYPE && (
                <img className="item-country" src={item?.image} alt="country" />
              )}
              {type === GAME_TYPE && (
                <img className="item-country" src={item?.thumnail} alt="thumnail" />
              )}
              <div className="item-title">
                {item?.name || item?.pointSymbol || item?.network_name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SelectCustom;

function DropdownSearch({ className = '', type, ...props }) {
  return (
    <div className={`search-dropdown-wrapper ${className}`}>
      {type === SEARCH_TYPE && (
        <div className="search-icon-wrapper">
          <SearchIcon />
        </div>
      )}
      <input {...props} type="text" className="search-country-input" />
    </div>
  );
}
