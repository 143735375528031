import React, { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import SelectCustom from '../../components/common/SelectCustom';
import { GAME_TYPE } from '../../constants';
import TButton from '../../components/common/Button';
import ReferralTable from '../../components/common/CommonTable/ReferralTable';
import referralApi from '../../services/referral';
import formatPointNumber from '../../lib/formatPointNumber';

function ReferralRewardTab(props) {
  const { listGame, listChain } = props;
  const { t } = useTranslation();
  const columnReferralReward = [
    {
      Header: ' ',
      columns: [
        {
          Header: t('Chain'),
          accessor: (data) => <p className="">{data?.chain?.network_name}</p>,
        },
        {
          Header: t('Token'),
          accessor: (data) => (
            <div className="token-wrapper">
              <p>{data?.tokenSymbol}</p>
              <img src={data?.tokenImage} alt="token-symbol" className="img-token-symbol" />
            </div>
          ),
        },
        {
          Header: t('Earned'),
          accessor: (data) => <p>{formatPointNumber(data?.totalReward)}</p>,
        },
        {
          Header: t('Balance'),
          accessor: (data) => <p className="balance-token">{formatPointNumber(data?.balance)}</p>,
        },
      ],
    },
  ];
  const [gameFilter, setGameFilter] = useState();
  const [chainSelected, setChainSelected] = useState();
  const [referralReward, setReferralReward] = useState();
  const headerTextAlignCenter = [t('Earned'), t('Balance')];

  useQueries({
    queries: [
      {
        queryKey: ['getListReferralRewardPoint', gameFilter, chainSelected],
        queryFn: async () => {
          const queryObj = {};
          if (gameFilter) {
            queryObj.gameId = gameFilter;
          }
          if (chainSelected) {
            queryObj.chainId = chainSelected;
          }
          const res = await referralApi.getListReferralRewardPoint(queryObj);
          setReferralReward(res?.data);
          return res?.data;
        },
      },
    ],
  });

  const handleResetFilter = () => {
    setChainSelected(undefined);
    setGameFilter(undefined);
  };
  return (
    <div>
      <div className="my-reward-wrapper">
        <div className="select-btn-wrapper">
          <div className="btn-select-game-wrapper">
            <SelectCustom
              items={listGame}
              selectedItem={gameFilter}
              setSelectedItem={setGameFilter}
              classNameDropdown="btn-select-game"
              type={GAME_TYPE}
              textDefault={t('referral.selectGame')}
              selectAllGame
            />
          </div>
          <div className="btn-select-game-wrapper">
            <SelectCustom
              items={listChain}
              selectedItem={chainSelected}
              setSelectedItem={setChainSelected}
              classNameDropdown="btn-select-game"
              textDefault={t('Select chain')}
              keyShow="chain_id"
            />
          </div>
          <div className="btn-reset-filter-wrapper">
            <TButton
              disabled={!chainSelected && !gameFilter}
              onClick={handleResetFilter}
              className="btn-clear-filter"
            >
              {t('referral.clearFilters')}
            </TButton>
          </div>
        </div>
      </div>
      <div className="referral-reward-wrapper">
        <ReferralTable
          headerTextAlignCenter={headerTextAlignCenter}
          pageSizePagination={99}
          columns={columnReferralReward}
          data={referralReward || []}
        />
      </div>
    </div>
  );
}

export default ReferralRewardTab;
