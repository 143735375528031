import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/common/Layout';
import './style.scss';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import Pagination from '../../components/common/Pagination/index';
import tournamentApi from '../../services/tournament';
import UnauthorizePage from '../UnauthorizePage/UnauthorizePage';
import useMessage from '../../components/common/toast/UseMessage';
import { ERR_MESSAGE, GAME_TYPE } from '../../constants';
import { useAuthContext } from '../../context/AuthContext';
import ReferralTable from '../../components/common/CommonTable/ReferralTable';
import SelectCustom from '../../components/common/SelectCustom';
import scholarshipApi from '../../services/scholarship';
import ModalDateRange from '../../components/common/Modal/ModalDateRange';
import { ICONS } from '../../../assets/imgs';
import formatPointNumber from '../../lib/formatPointNumber';
import TButton from '../../components/common/Button';

function TransactioHistory(props) {
  const { t } = useTranslation();
  const headerTextAlignCenter = [
    t('transactionHistory.amount'),
    t('transactionHistory.description'),
    t('transactionHistory.type'),
  ];
  const headerTextAlignRight = [t('transactionHistory.date')];

  const columnsTransactionHistory = [
    {
      Header: ' ',
      columns: [
        {
          Header: t('transactionHistory.from'),
          accessor: (data) => <p>{data?.from}</p>,
        },
        {
          Header: t('transactionHistory.type'),
          accessor: (data) => (
            <p className={`${data?.type === 'in' ? 'success-status' : 'failed-status'}`}>
              {data?.type === 'in' ? 'In' : 'Out'}
            </p>
          ),
        },

        {
          Header: t('transactionHistory.amount'),
          accessor: (data) => (
            <p>
              {formatPointNumber(data?.amount)} {data?.tokenSymbol} Points
            </p>
          ),
        },
        {
          Header: t('transactionHistory.description'),
          accessor: (data) => <p id="description">{data?.txAction}</p>,
        },
        {
          Header: t('transactionHistory.date'),
          accessor: (data) => (
            <p>
              {format(
                parseISO(data?.createdAt ? data?.createdAt : new Date().toISOString()),
                'dd/MM/yyyy',
              )}
            </p>
          ),
        },
      ],
    },
  ];
  const { dataConnect } = props;
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPage, setTotalPage] = useState(2);
  const [transactionHistoryOverview, setTransactionHistoryOverview] = useState([]);
  const [gameFilter, setGameFilter] = useState();
  const [listGame, setListGame] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [dateRange, setDateRange] = useState(t('transactionHistory.dateRange'));
  const [params, setParams] = useState();

  const { isLogin } = useAuthContext();

  const getListGame = async () => {
    const res = await scholarshipApi.getListGame();
    setListGame(res.data);
  };

  const getTransactionHistory = async () => {
    dispatch(showLoading());
    const pageParams = {
      ...params,
      page: currentPage,
      limit: itemsPerPage,
    };
    try {
      const res = await tournamentApi.getTransactionHistory(pageParams);
      setTransactionHistoryOverview(res?.data?.pointChanges);
      setTotalPage(res?.data?.totalPage);
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getData = async () => {
    dispatch(showLoading());
    try {
      await getListGame();
    } catch (error) {
      openMessageError(error.response.data?.message || ERR_MESSAGE);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getTransactionHistory();
  }, [currentPage]);

  useEffect(() => {
    if (gameFilter) {
      setParams({
        ...params,
        gameId: gameFilter,
      });
      setCurrentPage(1);
    }
  }, [gameFilter]);

  useEffect(() => {
    getTransactionHistory();
  }, [params]);

  useEffect(() => {
    getData();
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const saveDateRange = () => {
    const parameter = { ...params };
    parameter.from = new Date(selectionRange.startDate);
    const currentEndDate = new Date(selectionRange.endDate);
    parameter.to = new Date(currentEndDate.setDate(currentEndDate.getDate() + 1));
    setDateRange(
      `${moment(selectionRange.startDate).format('DD/MM/YYYY')}-${moment(
        selectionRange.endDate,
      ).format('DD/MM/YYYY')}`,
    );
    setParams(parameter);
    setCurrentPage(1);
    setModalIsOpen(false);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const selectDateRange = () => {
    setModalIsOpen(true);
  };

  const handleResetFilter = () => {
    setDateRange(t('filter.dateRange'));
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    setParams(undefined);
    setGameFilter(undefined);
    setCurrentPage(1);
  };

  return (
    <Layout
      title={t('transactionHistory.default')}
      dataConnect={dataConnect}
      type={1}
      link="/assets"
    >
      {isLogin ? (
        <div>
          <div className="prize-body">
            <div className="reset-filter-wrapper">
              <div className="transaction-filter-wrapper">
                <div className="btn-select-game-wrapper">
                  <SelectCustom
                    items={listGame}
                    selectedItem={gameFilter}
                    setSelectedItem={setGameFilter}
                    classNameDropdown="btn-select-game"
                    type={GAME_TYPE}
                    textDefault={t('transactionHistory.selectGame')}
                  />
                </div>
                <div className="btn-select-date-range" onClick={selectDateRange} aria-hidden>
                  {dateRange}
                  <img src={ICONS.iconCalendar} alt="dgpub-network" className="icon-calendar" />
                </div>
              </div>
              <div className="btn-reset-filter-wrapper">
                <TButton
                  disabled={!params}
                  onClick={handleResetFilter}
                  className="btn-clear-filter"
                >
                  {t('transactionHistory.clearFilters')}
                </TButton>
              </div>
            </div>
            <div className="reward-history-table">
              <ReferralTable
                headerTextAlignRight={headerTextAlignRight}
                headerTextAlignCenter={headerTextAlignCenter}
                pageSizePagination={itemsPerPage}
                columns={columnsTransactionHistory}
                data={transactionHistoryOverview}
              />
              <div className="reward-pagination">
                <Pagination
                  totalPage={totalPage}
                  onChange={handlePageChange}
                  forcePage={currentPage}
                />
              </div>
            </div>
          </div>
          <ModalDateRange
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            selectionRange={selectionRange}
            handleSelect={handleSelect}
            saveDateRange={saveDateRange}
          />
        </div>
      ) : (
        <UnauthorizePage />
      )}
    </Layout>
  );
}

export default TransactioHistory;
